<template>
  <v-layout v-if="Blog != null" wrap>
    <Header
      titulo=""
      imagen="https://www.ofimania.com/img/Header/Blog.webp"
    />
    <Separador titulo="Publicaciones Recientes" />
    <Paginacion Blog :array="Blog" :slice="14" />
  </v-layout>
</template>

<script>
import Header from "../components/Header.vue";
import Paginacion from "../components/Paginacion.vue";
import Separador from "../components/Separador.vue";
import Banners from "@/components/Banners.vue";
import { mapState } from "vuex";

export default {
  metaInfo: {
    title: "Blog | Ofimania",
  },
  created() {
    this.axios
      .get("https://www.ofimania.com/API/Imagen/Banner-Blog")
      .then((response) => {
        this.img = response.data;
      })
      .catch((error) => {
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Error de Conexion",
          info: "Verifique su Conexion a Internet",
        };
      });
    if (this.Blog == null) {
      this.$store.state.cargando = true;
      this.axios
        .get("https://www.ofimania.com/API/Blog/Get")
        .then((response) => {
          this.$store.state.Blog = response.data;
          this.$store.state.cargando = false;
        })
        .catch((error) => {
          this.$store.state.cargando = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet",
          };
        });
    } else {
      this.$store.state.cargando = false;
    }
  },
  components: {
    Paginacion,
    Separador,
    Banners,
    Header,
  },
  data: () => ({
    img: [],
  }),
  computed: {
    ...mapState(["movil", "Blog"]),
  },
};
</script>
